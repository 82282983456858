import { ThemeColors } from "gms-opal/theme";

export default class NavRoute {
  url: string;
  title: string;
  size: string;
  color: ThemeColors;
  activated: boolean;

  constructor(url: string, title: string, color: ThemeColors) {
    this.url = url;
    this.title = title;
    this.color = color;
    this.size = "col-6 col-md";
    this.activated = false;
  }
}
