import { Route, Routes } from "react-router-dom";
import styled from "@emotion/styled";
import "bootstrap/scss/bootstrap.scss";
import "style.scss";
import Banner from "gms-opal/components/banner";
import Nav from "gms-opal/components/nav";
import ImageList from "gms-opal/components/image-list";
import ImageRecordProvider from "gms-opal/providers/image-record-provider";
import PageCaption from "gms-opal/components/page-caption";
import CustomOrders from "gms-opal/components/custom-orders";
import Contact from "gms-opal/components/contact";
import Login from "gms-opal/components/login";
import FooterLinks from "gms-opal/components/footer-links";
import { Fragment } from "react";

const Title = styled.div`
  background-color: black;
  color: white;
`;

export default function App() {
  return (
    <Fragment>
      <Login />
      <main role="main" className="flex-shrink-0">
        <Title>
          <div className="container">
            <div className="row">
              <div className="col">
                <h1>GMS Opal</h1>
              </div>
            </div>
          </div>
        </Title>
        <Banner />
        <Nav />
        <ImageRecordProvider>
          <Routes>
            <Route path="/recently-cut" element={
              <div className="container">
                <PageCaption page="recently-cut" />
                <ImageList page="recently-cut" />
              </div>
            } />
            <Route path="/non-opal" element={
              <div className="container">
                <PageCaption page="non-opal" />
                <ImageList page="non-opal" />
              </div>
            } />
            <Route path="/custom-orders" element={<CustomOrders />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/" element={
              <div className="container">
                <PageCaption page="featured"/>
                <ImageList page="featured"/>
              </div>
            } />
            <Route path="*" element={<h3>Page not found.</h3>} />
          </Routes>
        </ImageRecordProvider>
      </main>
      <footer className="footer mt-auto py-3">
        <div className="container">
          <div className="text-muted mt-1 mb-1 float-start">
            Copyright &copy; 2020 GMS Opal. All rights reserved.
          </div>
          <FooterLinks />
          <div className="clearfix mb-4" />
        </div>
      </footer>
    </Fragment>
  );
}
