import { useState } from "react";
import { DragDropContext, Droppable, DroppableProvided, DropResult } from "@hello-pangea/dnd";
import ImageEdit from "gms-opal/components/image-edit";
import { ImageRecord, UpdateImagesFunction } from "gms-opal/providers/image-record-provider";
import ImageUpload from "./image-upload";

interface Props {
  page: string;
  imageRecords: ImageRecord[];
  updateImages: UpdateImagesFunction;
}

export default function ImageListEditContainer({ page, imageRecords, updateImages }: Props) {
  const [disabled, setDisabled] = useState<boolean>(false);

  const dragEnd = ({ source, destination }: DropResult) => {
    if (!source || !destination) {
      return;
    }

    setDisabled(true);

    const ids = imageRecords.map(({ id }) => id);
    const spliced = ids.splice(source.index, 1);
    ids.splice(destination.index, 0, ...spliced);

    const idsMap: { [key: string]: number } = {};
    for (let i = 0; i < ids.length; i++) {
      idsMap[ids[i]] = i;
    }

    const imagesToUpdate: ImageRecord[] = [];
    for (const image of imageRecords) {
      const sortOrder = idsMap[image.id];

      if (sortOrder !== undefined && image.sortOrder !== sortOrder) {
        console.log(`UPDATE ${image.id} -> ${sortOrder}`);
        imagesToUpdate.push({ ...image, sortOrder });
      }
    }

    if (imagesToUpdate.length) {
      updateImages(imagesToUpdate, true)
        .then(() => {
          setDisabled(false);
        })
        .catch((e) => {
          console.error(e);
          setDisabled(false);
        });
    } else {
      setDisabled(false);
    }
  };

  const updateNote = (note: string, image: ImageRecord, save?: boolean) => {
    updateImages([{ ...image, note }], save)
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <form onSubmit={() => {}}>
      <fieldset disabled={disabled}>
        <ImageUpload page={page} />
      </fieldset>
      <fieldset disabled={disabled}>
        <DragDropContext onDragEnd={(event) => dragEnd(event)}>
          <Droppable droppableId="images">
            {(droppable: DroppableProvided) => (
              <div className="row" ref={droppable.innerRef} {...droppable.droppableProps}>
                {imageRecords.map((image, index) => (
                  <ImageEdit
                    key={image.id}
                    image={image}
                    index={index}
                    updateNote={updateNote}
                  />
                ))}
                {droppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </fieldset>
    </form>
  );
}
