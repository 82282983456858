import { useImages } from "gms-opal/providers/image-record-provider";

interface Props {
  page: string;
}

export default function PageCaption({ page }: Props) {
  const { textRecords } = useImages(page);

  return (
    <div className="row">
      <div className="col">
        {textRecords.map(({ text }, index) => (
          <p key={index}>
            {text}
          </p>
        ))}
      </div>
    </div>
  );
}
