import ImageCard from "gms-opal/components/image-card";
import { ImageRecord } from "gms-opal/providers/image-record-provider";

interface Props {
  imageRecords: ImageRecord[];
}

export default function ImageListContainer({
  imageRecords,
}: Props) {
  return (
    <div className="row">
      {imageRecords.map((image) => (
        <ImageCard key={image.id} image={image} />
      ))}
    </div>
  );
}
