import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { COGNITO_CLIENT_ID, COGNITO_ISSUER, COGNITO_REDIRECT_URL } from "./config";

const userManager = new UserManager({
  authority: COGNITO_ISSUER,
  client_id: COGNITO_CLIENT_ID,
  redirect_uri: COGNITO_REDIRECT_URL,
  response_type: "code",
  scope: "openid profile email",
  automaticSilentRenew: true,
  stateStore: new WebStorageStateStore({ store: localStorage }),
  userStore: new WebStorageStateStore({ store: localStorage }),
});

export default userManager;
