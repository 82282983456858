import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import useHttp from "../hooks/http";
import { API_URL, JSON_URL } from "../config";
import { useIdToken } from "./auth-provider";

export interface ImageRecord {
  id: string;
  fileName: string;
  note: string;
  sortOrder: number;
  page: string | null;
  changed?: boolean;
}

export interface TextRecord {
  page: string;
  text: string;
}

interface ImageRecordResponse {
  images?: ImageRecord[];
}

interface Props extends PropsWithChildren<Record<never, never>> {}

export type ResetFunction = () => Promise<void>;
export type UpdateImagesFunction = (images: ImageRecord[], save?: boolean) => Promise<void>;
export type ProcessImageFunction = (image: ImageRecord) => Promise<void>;
export type DeleteImageFunction = (id: string) => Promise<void>;

export interface Context {
  loading: boolean;
  error: Error | undefined;
  imageRecords: ImageRecord[];
  textRecords: TextRecord[];
  reset: ResetFunction;
  updateImages: UpdateImagesFunction;
  processImage: ProcessImageFunction;
  deleteImage: DeleteImageFunction;
}

export interface ProcessImageParams {
  id?: string;
}

export interface DeleteImageParams {
  id?: string;
}

const initialValue: Context = {
  imageRecords: [],
  textRecords: [],
  loading: true,
  error: new Error("useImages() must be called within an <ImageRecordProvider />"),
  reset: async () => {
    throw new Error("useImages() must be called within an <ImageRecordProvider />");
  },
  updateImages: async (images: ImageRecord[], save?: boolean) => {
    console.info({ images, save });
    throw new Error("useImages() must be called within an <ImageRecordProvider />");
  },
  processImage: async (image: ImageRecord) => {
    console.info({ image });
    throw new Error("useImages() must be called within an <ImageRecordProvider />");
  },
  deleteImage: async (id: string) => {
    console.info({ id });
    throw new Error("useImages() must be called within an <ImageRecordProvider />");
  },
};

const ImageRecordContext = createContext<Context>(initialValue);

const TEXT_RECORDS: TextRecord[] = [
  {
    page: "featured",
    text: `
GMS Opal provides a variety of precious and semi-precious gems primarily from Idaho.
Our specialty is Idaho opal in calibrated and free-from shapes.
`,
  },
  {
    page: "recently-cut",
    text: `
Idaho doublet and triplet opal
`,
  },
];

const getImageMutationVariables = (imageRecords: ImageRecord[]): string => JSON.stringify({
  images: imageRecords.map((imageRecord) => ({
    id: imageRecord.id,
    note: imageRecord.note,
    sortOrder: imageRecord.sortOrder,
  })),
});

const compareImageRecords = (a: ImageRecord, b: ImageRecord): boolean => {
  const keys: (keyof ImageRecord)[] = ["note", "sortOrder"];

  return keys.every((k) => a[k] === b[k]);
};

export default function ImageRecordProvider({ children }: Props) {
  const idToken = useIdToken();
  const [imageRecords, setImageRecords] = useState<ImageRecord[]>([]);

  const {
    result: {
      loading,
      error,
      result: data = {},
    },
    request: refetch,
  } = useHttp<ImageRecordResponse>({
    url: JSON_URL,
    init: { method: "GET" },
    requestNow: true,
  });

  useEffect(
    () => {
      const dbImageRecords = data.images || [];
      setImageRecords(dbImageRecords);
    },
    [data.images],
  );

  const { request: mutateImages } = useHttp<ImageRecordResponse>({
    url: API_URL,
    init: {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken || "INVALID"}`,
      },
    },
  });

  const { request: processImageRequest } = useHttp<ImageRecordResponse, ProcessImageParams>({
    url: ({ id }: ProcessImageParams = {}) => `${API_URL}/${id}`,
    init: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken || "INVALID"}`,
      },
    },
  });

  const {
    request: deleteImageRequest,
    result: { loading: deleting },
  } = useHttp<ImageRecordResponse, DeleteImageParams>({
    url: ({ id }: ProcessImageParams = {}) => `${API_URL}/${id}`,
    init: {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken || "INVALID"}`,
      },
    },
  });

  const textRecords = TEXT_RECORDS;

  if (error) {
    console.error(error);
  }

  const reset: ResetFunction = async () => {
    await refetch();
  };

  const updateImages: UpdateImagesFunction = useCallback(
    async (images: ImageRecord[], save?: boolean) => {
      const allIndexesExist = images.every((i) => imageRecords.some((r) => r.id === i.id));

      if (!allIndexesExist) {
        return;
      }

      const copy = [...imageRecords];
      let someValueChanged = false;

      for (const image of images) {
        const index = copy.findIndex(({ id }) => image.id === id);

        if (index < 0) {
          return;
        }

        const imageIsDifferent = !compareImageRecords(image, copy[index]);
        if (imageIsDifferent) {
          copy[index] = { ...image, changed: true };
        }

        someValueChanged = someValueChanged || imageIsDifferent;
      }

      if (someValueChanged) {
        setImageRecords(copy);
      }

      const hasChanges = someValueChanged || copy.some((image) => image.changed);

      if (save && hasChanges) {
        const changedImages = copy.filter((it) => it.changed);
        await mutateImages({ body: getImageMutationVariables(changedImages) });

        setImageRecords(copy);
      }
    },
    [imageRecords, mutateImages],
  );

  const processImage = useCallback(
    async (image: ImageRecord) => {
      const { id, ...body } = image;

      const { result } = await processImageRequest({ body: JSON.stringify(body) }, { id });

      if (result && result.images) {
        setImageRecords(result.images);
      }
    },
    [processImageRequest],
  );

  const deleteImage = useCallback(
    async (id: string) => {
      const { result } = await deleteImageRequest({}, { id });

      if (result && result.images) {
        setImageRecords(result.images);
      }
    },
    [deleteImageRequest],
  );

  const context: Context = {
    loading: loading || deleting,
    error,
    imageRecords,
    textRecords,
    reset,
    updateImages,
    processImage,
    deleteImage,
  };

  return (
    <ImageRecordContext.Provider value={context}>
      {children}
    </ImageRecordContext.Provider>
  );
}

export function useImages(page?: string): Context {
  const context = useContext(ImageRecordContext);

  return {
    ...context,
    imageRecords: page
      ? context.imageRecords.filter((record) => record.page === page)
      : context.imageRecords,
    textRecords: page
      ? context.textRecords.filter((record) => !page || record.page === page)
      : context.textRecords,
  };
}
