export const THEME_COLORS = ["primary", "secondary1", "secondary2", "complement"] as const;
export type ThemeColorsTuple = typeof THEME_COLORS;
export type ThemeColors = ThemeColorsTuple[number];

export const SUPPORTED_BREAKPOINTS = ["mobile", "tablet", "laptop", "desktop"];
export type SupportedBreakpointsTuple = typeof SUPPORTED_BREAKPOINTS;
export type SupportedBreakpoints = SupportedBreakpointsTuple[number];

export type BreakpointGuide = "min" | "max";

export interface GMSOpalTheme {
  breakpoints: {
    [key in BreakpointGuide]: {
      [key in SupportedBreakpoints]: string;
    };
  };
  colors: {
    [key in ThemeColors]: [string, string, string, string, string];
  };
  getColor: (color?: ThemeColors, index?: number) => string;
}

// from bootstrap _variables.scss
export const breakpointValues = {
  mobile: 540,
  tablet: 720,
  laptop: 960,
  desktop: 1140,
} as const;

const theme: GMSOpalTheme = {
  breakpoints: {
    min: {
      mobile: `(min-width: ${breakpointValues.mobile}px)`,
      tablet: `(min-width: ${breakpointValues.tablet}px)`,
      laptop: `(min-width: ${breakpointValues.laptop}px)`,
      desktop: `(min-width: ${breakpointValues.desktop}px)`,
    },
    max: {
      mobile: `(max-width: ${breakpointValues.tablet - 1}px)`,
      tablet: `(max-width: ${breakpointValues.laptop - 1}px)`,
      laptop: `(max-width: ${breakpointValues.desktop - 1}px)`,
      desktop: `(min-width: ${breakpointValues.desktop}px)`,
    },
  },

  colors: {
    primary: [
      "#2C447A",
      "#7486AF",
      "#485E91",
      "#172D5F",
      "#061840",
    ],
    secondary1: [
      "#B63434",
      "#FF9F9F",
      "#D85F5F",
      "#8E1616",
      "#600000",
    ],
    secondary2: [
      "#2A922A",
      "#81CF81",
      "#4CAD4C",
      "#117211",
      "#004D00",
    ],
    complement: [
      "#B68B34",
      "#FFDF9F",
      "#D8B05F",
      "#8E6616",
      "#604000",
    ],
  },

  getColor: (color?: ThemeColors, index = 0) => {
    if (!color || index < 0 || index > 5) {
      return "#000000";
    }

    return theme.colors[color][index];
  },
};

export default theme;
