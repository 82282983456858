import { useEffect, useState } from "react";
import userManager from "../user-manager";

export default function Login() {
  const [search, setSearch] = useState(window.location.search);

  const setUrl = (params?: string | { [key: string]: string }, pathname?: string) => {
    const url = new URL(window.location.toString());

    if (params) {
      url.search = typeof params === "string" ? params : new URLSearchParams(params).toString();
    }

    if (pathname) {
      url.pathname = pathname;
    }

    setSearch(url.search);
    window.history.pushState({}, "", url.toString());
  };

  const params = new URLSearchParams(search);
  const code = params.get("code");

  useEffect(
    () => {
      if (!code) {
        return;
      }

      userManager.signinCallback()
        .then(async () => {
          setUrl({}, "/");
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [code],
  );

  return null;
}
