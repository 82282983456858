import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export const useRouteMatch = (path: string) => {
  const { pathname } = useLocation();

  const testPathParts = useMemo(
    () => path.split("/").filter((it) => !!it),
    [path],
  );

  const currentPathParts = useMemo(
    () => pathname.split("/").filter((it) => !!it),
    [pathname],
  );

  return testPathParts.length
    ? testPathParts.every((part, index) => currentPathParts[index] === part)
    : currentPathParts.length === 0;
};
