export default class RequestError<T> extends Error {
  status: number;
  details: T | undefined;

  constructor(response: Response, details?: T) {
    super();
    this.message = response.statusText;
    this.status = response.status;
    this.details = details;
  }
}
