import { ChangeEvent, Fragment, useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { XhrHttpHandler } from "@aws-sdk/xhr-http-handler";
import { v4 as uuid } from "uuid";
import stripExif from "gms-opal/classes/image-processor";
import { useIAMCredentials } from "gms-opal/providers/auth-provider";
import { S3_BUCKET_IMAGE_UPLOAD, S3_REGION } from "gms-opal/config";
import { useImages } from "gms-opal/providers/image-record-provider";
import useAsyncCallback from "gms-opal/hooks/use-async-callback";

interface Props {
  page: string;
}

const PreviewCard = styled.div`
  width: 363.625px;
  height: 225px;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

enum UploadState {
  NotUploading = 0,
  Processing = 1,
  Ready = 2,
  Uploading = 3,
  CreatingThumbnail = 4,
}

interface UploadStatus {
  fileName: string;
  blob: Blob | undefined;
  state: UploadState;
  uploadPercent: number;
}

const initialStatus = (): UploadStatus => ({
  fileName: "",
  blob: undefined,
  state: UploadState.NotUploading,
  uploadPercent: 0,
});

export default function ImageUpload({ page }: Props) {
  const iamCredentials = useIAMCredentials();

  const [status, setStatus] = useState<UploadStatus>(initialStatus());

  const { processImage, reset } = useImages();

  const fileCaption = useMemo(
    () => status.fileName ? `Pending File: ${status.fileName}` : "Choose an image to upload",
    [status.fileName],
  );

  const fileChange = useCallback(
    (event: ChangeEvent) => {
      const { files } = event.target as HTMLInputElement;

      if (files && files.length && files[0]) {
        setStatus({
          fileName: `${files[0].name}`,
          blob: undefined,
          state: UploadState.Processing,
          uploadPercent: 0,
        });

        stripExif(files[0])
          .then((result) => {
            setStatus({
              fileName: `${files[0].name}`,
              blob: result,
              state: UploadState.Ready,
              uploadPercent: 0,
            });
          })
          .catch((error) => {
            console.error(error);
            setStatus(initialStatus());
          });
      } else {
        setStatus(initialStatus());
      }
    },
    [],
  );

  const doUpload = useAsyncCallback(
    async () => {
      if (!status.blob || !status.fileName) {
        throw new Error("Nothing to upload!");
      }

      setStatus({
        fileName: status.fileName,
        blob: status.blob,
        state: UploadState.Uploading,
        uploadPercent: 0,
      });

      const id = uuid();

      const s3 = new S3Client({
        credentials: iamCredentials,
        requestHandler: new XhrHttpHandler({}),
        region: S3_REGION,
      });

      const uploadObj = new Upload({
        client: s3,
        params: {
          Bucket: S3_BUCKET_IMAGE_UPLOAD,
          Key: `images-full/${id}`,
          ContentType: "image/jpeg",
          Body: new File([status.blob], status.fileName),
        },
      });

      uploadObj.on("httpUploadProgress", (event) => {
        const loaded = +(event.loaded ?? 0);
        const total = +(event.total ?? 0);

        let percent = Math.round((10000 * loaded) / total) / 100;

        if (Number.isNaN(percent)) {
          percent = 0;
        }

        setStatus({
          fileName: status.fileName,
          blob: status.blob,
          state: UploadState.Uploading,
          uploadPercent: percent,
        });
      });

      await uploadObj.done();

      setStatus({
        fileName: status.fileName,
        blob: status.blob,
        state: UploadState.CreatingThumbnail,
        uploadPercent: 0,
      });

      await processImage({
        id,
        fileName: status.fileName,
        note: "",
        page,
        sortOrder: -1,
      });

      setStatus({
        fileName: "",
        blob: undefined,
        state: UploadState.Ready,
        uploadPercent: 0,
      });

      await reset();
    },
    (error) => {
      console.error(error);
    },
    [status.fileName, status.blob],
  );

  const preview = useMemo(
    () => (status.blob ? URL.createObjectURL(status.blob) : undefined),
    [status.blob],
  );

  return (
    <div className="row">
      <div className="col">
        <div className="card shadow-sm flex-row p-3 mb-3">
          <div className="flex-column flex-grow-1">
            <div>
              {status.state < UploadState.Uploading && (
                <Fragment>
                  <label className="form-label" htmlFor="fileInput">
                    {fileCaption}
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="fileInput"
                    onChange={fileChange}
                    accept=".jpg"
                  />
                </Fragment>
              )}
              {status.blob && status.state === UploadState.Ready && (
                <div className="pt-3">
                  <button type="button" className="btn btn-primary" onClick={doUpload}>
                    Upload Image
                  </button>
                </div>
              )}
              {status.state === UploadState.Uploading && (
                <div>
                  <p>
                    Uploading {status.fileName}...
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow={(status.uploadPercent)}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: `${Math.max(10, status.uploadPercent)}%` }}
                    >
                      {status.uploadPercent}%
                    </div>
                  </div>
                </div>
              )}
              {status.state === UploadState.CreatingThumbnail && (
                <div>
                  <p>
                    Creating thumbnail...
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow={100}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "100%" }}
                    >
                      Please wait
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <PreviewCard className="card flex-card ms-3 shadow-sm">
            {status.state >= UploadState.Ready && status.blob && (
              <a href={preview} target="_blank" rel="noopener noreferrer">
                <img src={preview} alt="" />
              </a>
            )}
          </PreviewCard>
        </div>
      </div>
    </div>
  );
}
