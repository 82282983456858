import { ImageRecord } from "gms-opal/providers/image-record-provider";
import { IMAGE_BASE_URL } from "gms-opal/config";

interface Props {
  image: ImageRecord;
}

export default function ImageCard({ image }: Props) {
  const fullsizeUrl = `${IMAGE_BASE_URL}images-full/${image.id}`;
  const thumbnailUrl = `${IMAGE_BASE_URL}images-thumbnails/${image.id}`;

  return (
    <div className="col-md-4">
      <div className="card flex-card mb-4 shadow-sm">
        <a className="img-link" href={fullsizeUrl} target="_blank" rel="noopener noreferrer">
          <img src={thumbnailUrl} alt="" />
        </a>
      </div>
    </div>
  );
}
