const stripExif = async (file: File): Promise<Blob | undefined> => {
  const reader = new FileReader();

  if (file.type !== "image/jpeg") {
    throw new Error(`Only JPEG (.jpg, .jpeg) files with type "image/jpeg" are allowed.  Received: "${file.type}"`);
  }

  const dataPromise = new Promise<ArrayBuffer>((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };

    reader.onerror = (event) => {
      reject(event);
    };
  });

  reader.readAsArrayBuffer(file);

  const data = await dataPromise;

  const dv = new DataView(data as ArrayBuffer);


  if (dv.getUint16(0) !== 0xffd8) {
    throw new Error("Image does not appear to be valid (bad file header).");
  }

  let app1 = dv.getUint16(2);

  let recess = 0;
  const pieces = [];

  for (let offset = 4; offset < dv.byteLength; offset += 2) {
    if (app1 === 0xffe1) {
      pieces.push({ recess, offset: offset - 2 });
      recess = offset + dv.getUint16(offset);
    } else if (app1 === 0xffda) {
      break;
    }

    offset += dv.getUint16(offset);
    app1 = dv.getUint16(offset);
  }

  if (!pieces.length) {
    throw new Error("Image does not appear to be valid (no image dat4a).");
  }

  const newPieces = pieces.map((piece) => data.slice(piece.recess, piece.offset));

  newPieces.push(data.slice(recess));

  return new Blob(newPieces, { type: "image/jpeg" });
};

export default stripExif;
