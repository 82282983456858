import { useLoggedIn } from "gms-opal/providers/auth-provider";
import userManager from "../user-manager";
import { COGNITO_CLIENT_ID, COGNITO_REDIRECT_URL, COGNITO_URL } from "../config";

export default function FooterLinks() {
  const authenticated = useLoggedIn();

  const logOut = () => {
    userManager.signoutRedirect()
      .catch((error) => {
        console.error(error);
      });

    window.location.href = `${COGNITO_URL}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${COGNITO_REDIRECT_URL}`;
  };

  return (
    <div className="btn-group float-end">
      {
        authenticated
          ? (
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary"
              onClick={() => logOut()}
            >
              Log Off
            </button>
          )
          : (
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary"
              onClick={() => userManager.signinRedirect()}
            >
              Log In
            </button>
          )
      }
    </div>
  );
}
