import { useCallback, DependencyList } from "react";

export type CallbackFunction<Z = any> = (...args: any[]) => Promise<Z>;
export type ResultFunction<Z = any> = (...args: any[]) => Promise<Z | void>;
export type ErrorFunction = (error: any) => void;

export default function useAsyncCallback<T>(
  callback: CallbackFunction<T>,
  handleError: ErrorFunction,
  deps: DependencyList,
): ResultFunction<T> {
  return useCallback(
    (...args) => callback(...args)
      .catch((error: any) => handleError(error)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, callback, handleError],
  );
}
