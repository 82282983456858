import { useCallback, useEffect, useState } from "react";
import RequestError from "../classes/request-error";

export interface UseHttpOptions<T = any, U = any> {
  url: string | ((params?: U) => string);
  init?: RequestInit;
  requestNow?: boolean;
  handleResponse?: (obj: Response) => Promise<any>;
  transform?: (obj: any) => (T | Promise<T>);
}

export interface UseHttpResult<T> {
  loading: boolean;
  result?: T;
  error?: Error;
}

type UseHttpRequest<T, U> = (options?: RequestInit, params?: U) => Promise<UseHttpResult<T>>;

interface UseHttpInterface<T, U> {
  request: UseHttpRequest<T, U>;
  result: UseHttpResult<T>;
}

const defaultHandleResponse = async (response: Response): Promise<any> => {
  const OK = response.status === 200 || response.status === 201 || response.status === 204;
  const NC = response.status === 201 || response.status === 204;

  let json: any = null;
  try {
    json = NC ? null : await response.json();
  } catch (error) {
    console.warn(error);
  }

  if (!OK) {
    throw new RequestError(response, json);
  }

  return json;
};

const defaultTransform = <T>(body: any): T => body as T;

export default function useHttp<T = any, U = any>({
  url,
  init,
  requestNow = false,
  handleResponse = defaultHandleResponse,
  transform = defaultTransform,
}: UseHttpOptions<T, U>): UseHttpInterface<T, U> {
  const [once, setOnce] = useState<boolean>(false);
  const [result, setResult] = useState<UseHttpResult<T>>({ loading: requestNow });

  const request = useCallback(
    (options?: RequestInit, params?: U): Promise<UseHttpResult<T>> => {
      setResult({ loading: true });

      let fetchUrl = url;
      if (typeof fetchUrl === "function") {
        fetchUrl = fetchUrl(params);
      }

      return fetch(fetchUrl, { ...init, ...options })
        .then(async (response: Response) => {
          const body = handleResponse(response);
          const transformed = await transform(body);
          const newResult: UseHttpResult<T> = { loading: false, result: transformed };
          setResult(newResult);
          return newResult;
        })
        .catch((error) => {
          const newResult: UseHttpResult<T> = { loading: false, error };
          setResult(newResult);
          return newResult;
        });
    },
    [url, init, handleResponse, transform],
  );

  useEffect(
    () => {
      if (once || !request || !requestNow) {
        return;
      }

      setOnce(true);
      request()
        .catch((error) => {
          console.warn(error);
        });
    },
    [once, request, requestNow],
  );

  return { request, result };
}
