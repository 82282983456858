export default function Contact() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h5>Contact</h5>
          <p className="email">
            Email: gmsopal<span className="d-none">-removeme</span>@gmail.<span className="d-none">com.</span>com
          </p>
          <p className="phone">
            Phone: (208) <span className="d-none">555-0621</span> 918-<span className="d-none">0000</span>0621
          </p>
        </div>
      </div>
    </div>
  );
}
