import { Link, LinkProps, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import { Theme } from "@emotion/react";
import NavRoute from "gms-opal/classes/nav-route";
import { ThemeColors } from "gms-opal/theme";

const NavContainer = styled.div`
  background-color: black;
  color: white;
  border-top: 2px solid white;
`;

const LinkContainer = styled.div`
  color: white;
  text-align: center;
`;

interface BlockLinkProps extends LinkProps {
  color?: ThemeColors;
}

const BlockLink = styled(Link)`
  color: white;
  text-decoration: none;
  display: block;
  padding: 1em 0;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 -15px;
  vertical-align: middle;
  position: relative;

  background-color: ${({ color, theme }: BlockLinkProps & { theme: Theme }) => theme.getColor(color)};
  
  &:visited, &:hover, &:active, &:link {
    color: white;
    text-decoration: none;
  }
  
  &.active::before {
    content: "";
    position: absolute;
    left: 50%;
    margin: 0 0 0 -0.5em;
    top: 0;
    width: 0;
    height: 0;
    border: 0.5em solid transparent;
    border-top-color: white;
  }
  
  &.active::after {
    content: "";
    position: absolute;
    left: 50%;
    margin: 0 0 0 -0.5em;
    bottom: 0;
    width: 0;
    height: 0;
    border: 0.5em solid transparent;
    border-bottom-color: white;
  }
`;

export default function Nav() {
  const routes: NavRoute[] = [
    new NavRoute("/", "Featured", "primary"),
    new NavRoute("/recently-cut", "Recently Cut", "secondary2"),
    new NavRoute("/non-opal", "Non-Opal Gems", "secondary1"),
    new NavRoute("/custom-orders", "Custom Orders", "complement"),
    new NavRoute("/contact", "Contact", "primary"),
  ];

  if (routes.length > 0 && routes.length % 2 === 1) {
    routes[routes.length - 1].size = "col-12 col-md";
  }

  const location = useLocation();
  for (const route of routes) {
    route.activated = route.url === location.pathname;
  }

  return (
    <NavContainer>
      <div className="container mb-4">
        <div className="row">
          {routes.map((route) => (
            <LinkContainer className={route.size} key={route.url}>
              <BlockLink color={route.color} to={route.url} className={route.activated ? "active" : "inactive"}>
                {route.title}
              </BlockLink>
            </LinkContainer>
          ))}
        </div>
      </div>
    </NavContainer>
  );
}
