import { Fragment, useState } from "react";
import styled from "@emotion/styled";
import { useImages } from "gms-opal/providers/image-record-provider";
import { useAuthenticated } from "gms-opal/providers/auth-provider";
import ImageListContainer from "gms-opal/components/image-list-container";
import ImageListEditContainer from "./image-list-edit-container";

const SwitchLabel = styled.label`
  user-select: none;
  cursor: pointer;
`;

interface Props {
  page: string;
}

export default function ImageList({ page }: Props) {
  const { loading, error, imageRecords, updateImages } = useImages(page);
  const authenticated = useAuthenticated("Admin");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  if (loading || !imageRecords) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }

  return (
    <Fragment>
      {authenticated && (
        <div className="row mb-4">
          <div className="col">
            <div className="form-check form-switch">
              <input
                id={`toggleIsEditing-${page}`}
                type="checkbox"
                className="form-check-input"
                checked={isEditing}
                onChange={(value) => { setIsEditing(value.target.checked); }}
              />
              <SwitchLabel
                className="form-check-label"
                htmlFor={`toggleIsEditing-${page}`}
              >
                Edit Images
              </SwitchLabel>
            </div>
          </div>
        </div>
      )}
      {
        isEditing
          ? (
            <ImageListEditContainer
              imageRecords={imageRecords}
              updateImages={updateImages}
              page={page}
            />
          )
          : <ImageListContainer imageRecords={imageRecords} />
      }
    </Fragment>
  );
}
