export const HTTPS_BASE = /local/.test(window.location.hostname) ? "https://gmsopal.com" : "";

export const API_URL = `${HTTPS_BASE}/api/images`;
export const JSON_URL = `${HTTPS_BASE}/image-data/images.json`;
export const COGNITO_URL = "https://pro-gms-opal.auth.us-west-2.amazoncognito.com";
export const COGNITO_ISSUER = "https://cognito-idp.us-west-2.amazonaws.com/us-west-2_6BkqUorkn";
export const COGNITO_REDIRECT_URL = "https://gmsopal.com";
export const COGNITO_CLIENT_ID = "181mmr8obf240ir9en99jjst8o";
export const COGNITO_IDENTITY_POOL_ID = "us-west-2:be61f4dd-267c-42e4-b65c-c4f39ade685f";
export const S3_BUCKET_IMAGE_UPLOAD = "pro-gms-opal-images";
export const S3_REGION = "us-west-2";
export const IMAGE_BASE_URL = `${HTTPS_BASE}/`;
