import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { decode as decodeToken } from "jsonwebtoken";
import { COGNITO_IDENTITY_POOL_ID } from "gms-opal/config";
import { User } from "oidc-client-ts";
import userManager from "../user-manager";
import { CognitoIdentityCredentialProvider, fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

interface Props extends PropsWithChildren<Record<never, never>> {}

export type DecodedToken = null | { [key: string]: any } | string;

export interface Context {
  loggedIn: boolean;
  iamCredentials?: CognitoIdentityCredentialProvider;
  groups: string[];
  idToken: string | null;
}

const initialValue: Context = {
  loggedIn: false,
  iamCredentials: undefined,
  groups: [],
  idToken: null,
};

const AuthContext = createContext<Context>(initialValue);

export default function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<User | undefined>();

  useEffect(
    () => {
      const userLoaded = (u: User) => {
        setUser(u);
      };

      const userUnloaded = () => {
        setUser(undefined);
      };

      userManager.events.addUserLoaded(userLoaded);
      userManager.events.addUserUnloaded(userUnloaded);

      return () => {
        userManager.events.removeUserLoaded(userLoaded);
        userManager.events.removeUserUnloaded(userUnloaded);
      };
    },
    [],
  );

  const idToken = useMemo(() => user?.id_token ?? null, [user]);
  const loggedIn = useMemo(() => !!idToken, [idToken]);
  const decodedToken = useMemo(() => decodeToken(idToken ?? "") ?? null as DecodedToken, [idToken]);

  const iamCredentials: CognitoIdentityCredentialProvider | undefined = useMemo(
    () => {
      if (!idToken || !decodedToken || typeof decodedToken !== "object") {
        return undefined;
      }

      const identityPoolId = COGNITO_IDENTITY_POOL_ID;
      const region = identityPoolId.split(":")[0];
      const issuer = `${decodedToken.iss || ""}`;

      const logins = {
        [issuer.replace(/^https?:\/\//i, "")]: idToken,
      };

      return fromCognitoIdentityPool({
        clientConfig: { region },
        identityPoolId,
        logins,
      });
    },
    [idToken, decodedToken],
  );

  const groups: string[] = (
    decodedToken
    && typeof decodedToken === "object"
    && decodedToken["cognito:groups"]
    && Array.isArray(decodedToken["cognito:groups"])
    && decodedToken["cognito:groups"]
      .filter((group) => (group && typeof group === "string"))
  ) || [];

  userManager.getUser()
    .catch((error) => {
      console.error(error);
    });

  const context = {
    loggedIn,
    iamCredentials,
    groups,
    idToken,
  };

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
}

export function useLoggedIn(): boolean {
  const { loggedIn } = useContext(AuthContext);
  return loggedIn;
}

export function useAuthenticated(group: string): boolean {
  const { loggedIn, groups } = useContext(AuthContext);

  return loggedIn && groups.includes(group);
}

export function useIdToken(): string | null {
  const { idToken } = useContext(AuthContext);
  return idToken;
}

export function useIAMCredentials(): CognitoIdentityCredentialProvider | undefined {
  const { iamCredentials } = useContext(AuthContext);
  return iamCredentials;
}
