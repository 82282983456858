import styled from "@emotion/styled";
import { Draggable, DraggableProvided } from "@hello-pangea/dnd";
import { ImageRecord, useImages } from "gms-opal/providers/image-record-provider";
import { IMAGE_BASE_URL } from "gms-opal/config";

interface Props {
  image: ImageRecord;
  index: number;
  updateNote: (note: string, image: ImageRecord, save?: boolean) => void;
}

const EditingFrame = styled.div`
  margin-bottom: 1rem;
  
  .card {
    float: right;
    width: 33%;
  }
  
  .drag {
    cursor: grab;
    padding-left: 2rem;

    span {
      font-weight: bold;
      font-size: 1.4rem;
      position: absolute;
      top: 0.05rem;
      left: 0.65rem;
    }
  }

  .disabled {
    cursor: not-allowed;
  }
`;

export default function ImageEdit({ image, index, updateNote }: Props) {
  const { deleteImage } = useImages();

  const fullsizeUrl = `${IMAGE_BASE_URL}images-full/${image.id}`;
  const thumbnailUrl = `${IMAGE_BASE_URL}images-thumbnails/${image.id}`;

  return (
    <Draggable draggableId={`images.${image.id}`} index={index} key={image.id} disableInteractiveElementBlocking>
      {(draggable: DraggableProvided) => (
        <div className="col-12" ref={draggable.innerRef} {...draggable.draggableProps}>
          <EditingFrame className="card shadow-sm flex-row">
            <div className="flex-column flex-grow-1">
              <div className="btn-group m-3">
                <button
                  type="button"
                  className="btn btn-primary drag"
                  {...draggable.dragHandleProps}
                >
                  <span role="img" aria-label="Move">⬍</span> Move
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    deleteImage(image.id)
                      .catch((error) => {
                        console.error(error);
                      });
                  }}
                >
                  <span role="img" aria-label="Delete">🗑️</span> Delete
                </button>
              </div>
              <div className="form-group m-3">
                <label htmlFor={`image-filename.${image.id}`}>File Name</label>
                <input
                  id={`image-filename.${image.id}`}
                  className="form-control form-control-sm"
                  type="text"
                  value={image.fileName}
                  readOnly
                />
              </div>
              <div className="form-group m-3">
                <label htmlFor={`image-note.${image.id}`}>Note</label>
                <input
                  id={`image-note.${image.id}`}
                  className="form-control form-control-sm"
                  type="text"
                  value={image.note}
                  onChange={(event) => updateNote(event.target.value, image)}
                  onBlur={(event) => updateNote(event.target.value, image, true)}
                />
              </div>
            </div>
            <div className="card flex-card m-3 shadow-sm">
              <a href={fullsizeUrl} target="_blank" rel="noopener noreferrer">
                <img src={thumbnailUrl} alt="" />
              </a>
            </div>
          </EditingFrame>
        </div>
      )}
    </Draggable>
  );
}
