import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import { createRoot } from "react-dom/client";
import App from "App";
import AuthProvider from "gms-opal/providers/auth-provider";
import { ThemeProvider } from "@emotion/react";
import theme from "gms-opal/theme";
import { StrictMode } from "react";
import { BrowserRouter } from "react-router-dom";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  </StrictMode>
);
