export default function CustomOrders() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h5>Custom cutting and polishing of Idaho opal</h5>
          <ul>
            <li>
              If you can visit us, you may select your own material to be cut, ground, and polished.
            </li>
            <li>
              If you have your own opal, even if not from Idaho, we can evaluate what you have and
              then prepare to your specifications.
            </li>
            <li>
              Other Idaho material including jaspers and agates can be shaped to your liking from
              material you have or from material we have in stock.
            </li>
          </ul>
          <p>
            At this time we are not able to produce custom gold or silver settings, but we will
            provide you with recommendations.
          </p>
          <p>
            We can set calibrated stones (standard sized) in a variety of gold or silver settings at
            lower costs to you.
          </p>
          <p>
            At all stages of custom order work, you will be provided photo and text updates to help
            you decide on the final product.
          </p>
        </div>
      </div>
    </div>
  );
}
