import styled from "@emotion/styled";
import bannerImage from "gms-opal/components/banner.jpg";
import { useRouteMatch } from "../hooks/route";

const BannerWrapper = styled.div`
  border-top: 2px solid white;
  height: 25em;
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  background-color: black;

  /* was max-width: 575px */
  @media ${({ theme }) => theme.breakpoints.max.mobile} {
    height: 15em;
  }
`;

const BannerImage = styled.img`
  width: 100%;
`;

export default function Banner() {
  const match = useRouteMatch("/");

  if (!match) {
    return null;
  }

  return (
    <BannerWrapper>
      <BannerImage src={bannerImage} alt="GMS Opal" />
    </BannerWrapper>
  );
}
